import React from 'react';
import Layout from "../components/layout";
import { ReactTypeformEmbed } from 'react-typeform-embed';

export default () => {
  return (
    <Layout>
      <div style={{position: "relative", height: "100vh", width: "100%"}}>
        <ReactTypeformEmbed url="https://marketingteam440440.typeform.com/to/ilauFs" autoOpen="true" />
      </div>
    </Layout>
  )
}





